import { Fragment } from 'react'

import { MAPS_LOADING_ERROR_MSG } from '../../constants/messages'
import { useCouponCountManager } from '../../hooks/useCouponсountManager'
import { Box, Button, Preloader, Text } from '../../ui/atoms'

const ChipsInfoWrapper = ({ children, ...props }) => (
  <Box
    {...props}
    w="calc(100% - 24px)"
    minHeight="132px"
    padding="24px 16px 28px 16px"
    border="2px solid rgba(255, 255, 255, 0.7)"
    borderRadius="16px"
    backgroundColor="rgba(69, 22, 216, 0.38)"
    margin="0 auto"
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    className="chips-info-wrapper"
  >
    {children}
  </Box>
)

const ChipsInfoError = ({ onRetry }) => (
  <Fragment>
    <Text
      mb="40px"
      fontWeight="700"
      fontSize="20px"
      lineHeight="26px"
      textAlign="center"
      letterSpacing="0.5px"
      color="#fff"
    >
      {MAPS_LOADING_ERROR_MSG}
    </Text>

    <Button variant="outlined" w="258px" onClick={onRetry}>
      Обновить
    </Button>
  </Fragment>
)

const ChipsInfoContent = ({ chips = 0 }) => (
  <Fragment>
    <Text
      as="h2"
      mb="18px"
      fontWeight="600"
      fontSize="24px"
      letterSpacing="0.5px"
      lineHeight="26px"
      textAlign="center"
      color="#fff"
    >
      Доступных фишек: {chips}
    </Text>
    <Text
      fontSize="18px"
      fontWeight="400"
      lineHeight="21px"
      textAlign="center"
      color="#fff"
    >
      Вклеивай фишки в картах ниже, чтобы получить коды для финального розыгрыша
    </Text>
  </Fragment>
)

export const ChipsInfo = ({
  isLoading = false,
  hasError = false,
  onRetry = () => {},
  ...props
}) => {
  const { chips } = useCouponCountManager()

  return (
    <ChipsInfoWrapper {...props}>
      {isLoading && <Preloader />}
      {!isLoading && hasError && <ChipsInfoError onRetry={onRetry} />}
      {!isLoading && !hasError && <ChipsInfoContent chips={chips} />}
    </ChipsInfoWrapper>
  )
}
