import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PATHS } from '../../routing/paths'
import { HOME_MOBILE_BACKGROUND } from '../../constants/backgrounds'
import { Box, Button, TextTitle } from '../../ui/atoms'
import { Notice } from '../../ui/organisms'
import { LayoutMobile } from '../../ui/layout'

const TextTitleHome = styled(TextTitle)`
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.textTertiaryColor}`};
`

const TextSubtitleHome = styled(TextTitle)`
  text-transform: none;
`

export const HomeMobile = () => {
  return (
    <LayoutMobile {...HOME_MOBILE_BACKGROUND}>
      <LayoutMobile.Content>
        <Notice mb="14px" />

        <Box
          alt="Акция завершена"
          maxWidth={{ _: '100%', md: '486px' }}
          mb="14px"
          style={{ textAlign: 'center' }}
        >
          <TextTitleHome fontSize="40px">Акция</TextTitleHome>
          <TextTitleHome fontSize="40px">завершена!</TextTitleHome>
        </Box>

        <Box
          alt="До встречи в новых играх"
          maxWidth={{ _: '100%', md: '486px' }}
          style={{ textAlign: 'center' }}
        >
          <TextSubtitleHome fontSize="24px">
            До встречи в новых играх!
          </TextSubtitleHome>
        </Box>
      </LayoutMobile.Content>
    </LayoutMobile>
  )
}
