import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { FINAL_DRAW_NO_CODES_MSG } from '../../constants/messages'
import { FANAL_DRAW_MOBILE_BACKGROUND } from '../../constants/backgrounds'
import { PATHS } from '../../routing/paths'
import { Button, Box, TextTitle } from '../../ui/atoms'
import { Pager } from '../../ui/molecules'
import { LayoutMobile } from '../../ui/layout'
import { CodeList } from '../../ui/organisms'

const TextTitleFinalDrawMobile = styled(TextTitle)`
  text-shadow: ${({ theme }) => `3px 3px ${theme.colors.textTertiaryColor}`};
`

export const FinalDrawMobile = ({
  finalDrawCodes,
  isLoading,
  hasError,
  onRetry,
}) => (
  <LayoutMobile {...FANAL_DRAW_MOBILE_BACKGROUND}>
    <LayoutMobile.Header>
      <TextTitleFinalDrawMobile fontSize="37px" textAlign="center" mb="25px">
        Розыгрыши
      </TextTitleFinalDrawMobile>

      <Box mb="24px">
        <Pager />
      </Box>

      <TextTitle fontSize="16px" mb="22px" textAlign="center">
        Мои коды
      </TextTitle>
    </LayoutMobile.Header>

    <LayoutMobile.Content>
      <CodeList
        isWideScreen={false}
        codes={finalDrawCodes}
        isLoading={isLoading}
        hasError={!!hasError}
        onRetry={onRetry}
        emptyMessage={FINAL_DRAW_NO_CODES_MSG}
      />

      {!hasError && (
        <Link to={PATHS.MAPS}>
          <Button variant="primary" w="100%" py="13px">
            Заполнить карту
          </Button>
        </Link>
      )}
    </LayoutMobile.Content>
  </LayoutMobile>
)
