export const RAFFLES_REG_TERM = [
  {
    from: '2023-08-07 00:00',
    to: '2023-08-13 23:59',
    raffle: { day: 15, month: 'августа' },
  },
  {
    from: '2023-08-14 00:00',
    to: '2023-08-20 23:59',
    raffle: { day: 22, month: 'августа' },
  },
  {
    from: '2023-08-21 00:00',
    to: '2023-08-27 23:59',
    raffle: { day: 29, month: 'августа' },
  },
  {
    from: '2023-08-28 00:00',
    to: '2023-09-03 23:59',
    raffle: { day: 5, month: 'сентября' },
  },
  {
    from: '2023-09-04 00:00',
    to: '2023-09-10 23:59',
    raffle: { day: 12, month: 'сентября' },
  },
  {
    from: '2023-09-11 00:00',
    to: '2023-09-17 23:59',
    raffle: { day: 19, month: 'сентября' },
  },
  {
    from: '2023-09-18 00:00',
    to: '2023-09-24 23:59',
    raffle: { day: 26, month: 'сентября' },
  },
  {
    from: '2023-09-25 00:00',
    to: '2023-10-01 23:59',
    raffle: { day: 4, month: 'октября' },
  },
]
