import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { PATHS } from '../../routing/paths'
import { MAP_STATUS } from '../../constants/maps'
import { Text } from '../../ui/atoms'

const MapListWrapper = styled.div`
  position: absolute;
  display: ${({ show }) => (show ? 'block' : 'none')};
  top: ${({ topOffset }) => `${topOffset}px`};
  width: ${({ width }) => `${width}px`};
  max-height: 288px;
  overflow: hidden;
  overflow-y: auto;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #fff;
  }
`

const MapListContent = styled.ul`
  width: ${({ width }) => `${width}px`};
`

const MapsListScroller = styled.div`
  height: 0;
  opacity: 0;
`

const MapListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 8px 8px 8px 16px;
  box-shadow: 0.886243px 2.6587303px 18.61111px 0px #10575e4f;
  border-radius: 12px;
  background: #fff;
  margin: 12px;

  @media (min-width: 1200px) {
    min-height: 80px;
    padding: 12px 14px 12px 20px;
  }
`

const MapsListLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const MapsListItemIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isWideViewport }) => (isWideViewport ? '80px' : '58px')};
  height: ${({ isWideViewport }) => (isWideViewport ? '56px' : '40px')};
  border-radius: 12px;
  background-color: ${({ isCompleted, isWideViewport, theme }) => {
    let compeletedBgColor = ''
    if (isWideViewport) {
      compeletedBgColor = 'rgba(69, 22, 216, 0.2)'
    } else {
      compeletedBgColor = 'rgba(69, 22, 216, 0.38)'
    }
    return isCompleted ? compeletedBgColor : theme.colors.red[0]
  }};
  border: ${({ isCompleted, isWideViewport, theme }) => {
    let completedBorderColor = ''
    if (isWideViewport) {
      completedBorderColor = theme.colors.textSecondaryColor
    } else {
      completedBorderColor = theme.colors.textTertiaryColor
    }
    return isCompleted
      ? `2px solid ${completedBorderColor}`
      : `2px solid ${theme.colors.red[0]}`
  }};
`
const MAP_LIST_ITEM_MARGIN_X_TOTAL_PX = 24
const MAP_LIST_CONTAINER_OFFSET_PX = 20

export const MapList = ({ maps, isWideViewport, topOffset, listItemWidth }) => {
  const scrollerRef = useRef()

  useEffect(() => {
    if (!maps || !maps.length) return

    scrollerRef.current.scrollIntoView()
  })

  if (!maps || !maps.length) {
    return null
  }

  let checkMarkName = ''
  if (isWideViewport) {
    checkMarkName = 'check-desktop.svg'
  } else {
    checkMarkName = 'check-mobile.svg'
  }

  return (
    <MapListWrapper
      topOffset={topOffset}
      show={!!listItemWidth}
      width={
        listItemWidth +
        MAP_LIST_ITEM_MARGIN_X_TOTAL_PX +
        MAP_LIST_CONTAINER_OFFSET_PX
      }
    >
      <MapListContent width={listItemWidth + MAP_LIST_ITEM_MARGIN_X_TOTAL_PX}>
        {maps.map((unit, idx) => (
          <MapListItem key={idx}>
            <MapsListLink
              to={PATHS.MAP_VIEW}
              state={{ mapID: unit.id, mapTitle: `Карта №${idx + 1}` }}
            />

            <Text
              fontWeight="500"
              fontSize={{ _: '18px', lg: '24px' }}
              lineHeight={{ _: '24px', lg: '32px' }}
            >
              Карта №{idx + 1}
            </Text>

            <MapsListItemIndicator
              isCompleted={unit.status === MAP_STATUS.COMPLETED}
              isWideViewport={isWideViewport}
            >
              {unit.status === MAP_STATUS.COMPLETED ? (
                <img src={`img/${checkMarkName}`} alt="" />
              ) : (
                <img src="img/arrow-right.svg" alt="" />
              )}
            </MapsListItemIndicator>
          </MapListItem>
        ))}
      </MapListContent>
      <MapsListScroller ref={scrollerRef} />
    </MapListWrapper>
  )
}
