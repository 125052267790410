import { Fragment } from 'react'
import styled, { css } from 'styled-components'

import { CODES_LOADING_ERROR_MSG } from '../../constants/messages'
import { weekDatesRange } from '../../utils/date'
import { Box, Button, Flex, Preloader, Text } from '../../ui/atoms'

const CodeListWrapper = styled(Box)`
  display: flex;
  overflow: hidden;
  border: 2px dashed #fff;
  border-radius: 16px;
  background-color: rgba(69, 22, 216, 0.38);
  ${({ hasCodes, isWideScreen, isWeeklyDraw, isLoading, hasError }) => {
    if (isWideScreen) {
      if (isWeeklyDraw) {
        return css`
          min-height: 504px;
          max-height: 504px;
          margin-bottom: 28px;
        `
      } else {
        return hasCodes
          ? css`
              min-height: 232px;
              max-height: 232px;
              margin-bottom: 28px;
            `
          : css`
              min-height: 354px;
              max-height: 354px;
              margin-bottom: 28px;
            `
      }
    } else {
      if (isWeeklyDraw) {
        if (!isLoading && !hasError && !hasCodes) {
          return css`
            align-items: start;
            padding-top: 133px;
            min-height: 415px;
            max-height: 415px;
            margin-bottom: 20px;
          `
        } else {
          return css`
            min-height: 415px;
            max-height: 415px;
            margin-bottom: 20px;
          `
        }
      } else {
        if ((isLoading && !hasCodes) || (!isLoading && hasError)) {
          return css`
            min-height: 415px;
            max-height: 415px;
            margin-bottom: 20px;
          `
        } else if (!isLoading && !hasError && !hasCodes) {
          return css`
            align-items: start;
            padding-top: 90px;
            min-height: 328px;
            max-height: 328px;
            margin-bottom: 20px;
          `
        } else {
          return css`
            min-height: 328px;
            max-height: 328px;
            margin-bottom: 20px;
          `
        }
      }
    }
  }};
`

CodeListWrapper.defaultProps = {
  maxHeight: '100%',
}

const CodesListBody = styled.ul`
  flex-grow: 1;
  max-height: 100%;
  overflow: hidden;
  padding-right: 16px;
  padding-left: 16px;
  overflow-y: auto;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;

  @media (min-width: 1200px) {
    padding-right: 32px;
    padding-left: 32px;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #fff;
  }
`

const CodeListItem = styled.li`
  padding: 16px;
  border-bottom: 2px dashed #fff;

  &:last-child {
    border-bottom: none;
  }
`

const CodeListLoading = () => (
  <Flex w="100%" alignItems="center" justifyContent="center" p="16px">
    <Preloader />
  </Flex>
)

const CodeListEmpty = ({ title }) => (
  <Flex alignItems="center" w="100%" maxWidth="288px" mx="auto">
    <Text
      fontWeight="500"
      fontSize="20px"
      lineHeight="26px"
      textAlign="center"
      letterSpacing="0.5px"
      color="#fff"
    >
      {title}
    </Text>
  </Flex>
)

const CodeListError = ({ onRetry }) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    w="100%"
    maxWidth="288px"
    mx="auto"
    p="16px"
  >
    <Text
      mb="40px"
      fontWeight="500"
      fontSize="20px"
      lineHeight="26px"
      textAlign="center"
      letterSpacing="0.5px"
      color="#fff"
    >
      {CODES_LOADING_ERROR_MSG}
    </Text>

    <Button variant="outlined" w="100%" onClick={onRetry}>
      Обновить
    </Button>
  </Flex>
)

const CodeListContent = ({ codes }) => (
  <CodesListBody>
    {codes.map((unit, idx) => (
      <Fragment key={idx}>
        {unit.week && (
          <CodeListItem>
            <Text
              fontSize="20px"
              fontWeight="500"
              lineHeight="28px"
              textAlign="center"
              color="#fff"
            >
              {weekDatesRange(unit.coupons[0].date)}
            </Text>
          </CodeListItem>
        )}
        {unit.coupons.map((coupon, idx) => (
          <CodeListItem key={idx}>
            <Text
              fontSize="18px"
              fontWeight="400"
              lineHeight="24px"
              textAlign="center"
              color="#fff"
            >
              {coupon.code}
            </Text>
          </CodeListItem>
        ))}
      </Fragment>
    ))}
  </CodesListBody>
)

export const CodeList = ({
  isWeeklyDraw,
  isWideScreen,
  codes = [],
  isLoading = false,
  hasError = false,
  emptyMessage = '',
  onRetry = () => {},
  ...props
}) => (
  <CodeListWrapper
    {...props}
    hasCodes={!!codes.length}
    isWeeklyDraw={isWeeklyDraw}
    isWideScreen={isWideScreen}
    isLoading={isLoading}
    hasError={hasError}
  >
    {isLoading && !codes.length && <CodeListLoading />}
    {!isLoading && hasError && <CodeListError onRetry={onRetry} />}
    {!isLoading && !hasError && !codes.length && (
      <CodeListEmpty title={emptyMessage} />
    )}
    {!isLoading && !hasError && codes.length > 0 && (
      <CodeListContent codes={codes} />
    )}
  </CodeListWrapper>
)
