import React from 'react'
import styled from 'styled-components'

import { Box, Container } from '../../ui/atoms'
import { NavbarDesktop, Footer } from '../../ui/organisms'

const LayoutDesktopWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 900px;
  height: 100vh;
`

const LayoutDesktopContent = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
  padding-top: 66px;
  padding-bottom: 170px;
`

export const LayoutDesktop = React.forwardRef(({ children, ...props }, ref) => (
  <LayoutDesktopWrapper ref={ref} {...props}>
    <NavbarDesktop />

    <LayoutDesktopContent>{children}</LayoutDesktopContent>

    <Footer />
  </LayoutDesktopWrapper>
))
