import { Box, Flex, Container, Text } from '../../ui/atoms'

export const Footer = () => (
  <Box as="footer" py="32px">
    <Container>
      <Text
        as="a"
        href="https://vk.com/spar_kd"
        textDecoration={{ _: 'underline', hover: 'none' }}
        style={{
          display: 'flex',
          alignItems: 'center',
          textDecorationColor: '#ffffff',
        }}
      >
        <Box as="img" src="img/vk_logo.svg" alt="" mr="8px" />
        <Text color="#ffffff">ВКонтакте</Text>
      </Text>
    </Container>
  </Box>
)
