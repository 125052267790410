import { useEffect, useState } from 'react'
import useMatchMedia from 'react-use-match-media'

import { WEEKLY_DRAW_REALIZE_FAIL_MESSAGE } from '../../constants/messages'

import { usePopupState } from '../../hooks/usePopupState'
import { useWeeklyDrawManager } from '../../hooks/useWeeklyDrawManager'
import { useCouponCountManager } from '../../hooks/useCouponсountManager'

import { PopupAlert } from '../../ui/molecules'
import { PopupCodesRealize, PopupCodeRegSuccess } from '../../ui/organisms'
import { WeeklyDrawMobile } from './WeeklyDrawMobile'
import { WeeklyDrawDesktop } from './WeeklyDrawDesktop'
import { GlobalStyle } from 'ui/settings/global'

export const WeeklyDraw = () => {
  const [isCouponsRealizePending, setIsCouponsRealizePending] = useState(false)
  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const {
    weeklyDrawCodes,
    isLoading,
    error,
    fetchWeeklyCodes,
    realizeWeeklyCodes,
  } = useWeeklyDrawManager()
  const { coupons } = useCouponCountManager()
  const {
    isOpen: isRealizeCouponsPopupOpen,
    onOpen: onRealizeCouponsPopupOpen,
    onClose: onRealizeCouponsPopupClose,
  } = usePopupState()
  const {
    isOpen: isRealizeSuccessPopupOpen,
    onOpen: onRealizeSuccessPopupOpen,
    onClose: onRealizeSuccessPopupClose,
  } = usePopupState()
  const {
    isOpen: isRealizeFailPopupOpen,
    onOpen: onRealizeFailPopupOpen,
    onClose: onRealizeFailPopupClose,
  } = usePopupState()

  useEffect(() => {
    if (coupons > 0) {
      onRealizeCouponsPopupOpen()
    }
  }, [coupons])

  const onCouponsRealize = async () => {
    setIsCouponsRealizePending(true)

    try {
      await realizeWeeklyCodes().unwrap()
      await fetchWeeklyCodes().unwrap()

      onRealizeCouponsPopupClose()
      setIsCouponsRealizePending(false)
      onRealizeSuccessPopupOpen()
    } catch (error) {
      onRealizeCouponsPopupClose()
      setIsCouponsRealizePending(false)
      onRealizeFailPopupOpen()
    }
  }

  return (
    <GlobalStyle>
      {isWideViewport ? (
        <WeeklyDrawDesktop
          weeklyDrawCodes={weeklyDrawCodes}
          isLoading={isLoading}
          hasError={error}
          onRetry={fetchWeeklyCodes}
        />
      ) : (
        <WeeklyDrawMobile
          weeklyDrawCodes={weeklyDrawCodes}
          isLoading={isLoading}
          hasError={error}
          onRetry={fetchWeeklyCodes}
        />
      )}
      <PopupCodesRealize
        isOpened={isRealizeCouponsPopupOpen}
        onClose={onRealizeCouponsPopupClose}
        coupons={coupons}
        onCouponsRealize={onCouponsRealize}
        isCouponsRealizePending={isCouponsRealizePending}
      />
      <PopupCodeRegSuccess
        isOpened={isRealizeSuccessPopupOpen}
        onClose={onRealizeSuccessPopupClose}
      />
      <PopupAlert
        message={WEEKLY_DRAW_REALIZE_FAIL_MESSAGE}
        isOpened={isRealizeFailPopupOpen}
        onClose={onRealizeFailPopupClose}
      />
    </GlobalStyle>
  )
}
