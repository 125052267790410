import styled from 'styled-components'

import { WEEKLY_DRAW_DESKTOP_BACKGROUND } from '../../constants/backgrounds'
import { WEEKLY_DRAW_NO_CODES_MSG } from '../../constants/messages'
import { RAFFLES_REG_TERM } from 'constants/raffles'
import { getClosestRaffleDate } from 'utils/date'
import { Box, Flex, Text, TextTitle } from '../../ui/atoms'
import { NoticeItem } from '../../ui/molecules'
import { LayoutDesktop } from '../../ui/layout'
import { CodeList } from '../../ui/organisms'

const TextTitleWeeklyDraw = styled(TextTitle)`
  text-shadow: ${({ theme }) => `3px 3px ${theme.colors.blue[0]}`};
`

export const WeeklyDrawDesktop = ({
  weeklyDrawCodes,
  isLoading,
  hasError,
  onRetry,
}) => {
  const closestRaffle = getClosestRaffleDate(new Date(), RAFFLES_REG_TERM)

  return (
    <LayoutDesktop {...WEEKLY_DRAW_DESKTOP_BACKGROUND}>
      <Flex alignItems="center" justifyContent="center" mb="34px">
        <NoticeItem title="27" caption="iPhone 14" w="91px" mr="32px" />

        <Box w="546px" mr="32px">
          <TextTitleWeeklyDraw
            fontSize="56px"
            fontWeight="500"
            letterSpacing="0.5px"
            textAlign="center"
          >
            Еженедельный розыгрыш
          </TextTitleWeeklyDraw>
        </Box>

        <NoticeItem
          title={closestRaffle.day}
          subtitle={closestRaffle.month}
          caption="Ближайший розыгрыш"
          w="91px"
        />
      </Flex>

      <TextTitle mb="27px" fontSize="24px" textAlign="center">
        Мои коды
      </TextTitle>

      <Box
        w="100%"
        maxWidth="588px"
        mx="auto"
        minHeight="0"
        minWidth="0"
        style={{ flexGrow: 1 }}
      >
        <CodeList
          isWideScreen
          codes={weeklyDrawCodes}
          isLoading={isLoading}
          hasError={!!hasError}
          onRetry={onRetry}
          isWeeklyDraw
          emptyMessage={WEEKLY_DRAW_NO_CODES_MSG}
          h="100%"
        />
      </Box>
    </LayoutDesktop>
  )
}
