import { useEffect } from 'react'
import useMatchMedia from 'react-use-match-media'

import { usePopupState } from '../../hooks/usePopupState'
import { useCouponCountManager } from '../../hooks/useCouponсountManager'
import { useFinalDrawManager } from '../../hooks/useFinalDrawManager'

import { PopupChipsRealize } from '../../ui/organisms'
import { FinalDrawMobile } from './FinalDrawMobile'
import { FinalDrawDesktop } from './FinalDrawDesktop'
import { GlobalStyle } from 'ui/settings/global'

export const FinalDraw = () => {
  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const { chips } = useCouponCountManager()
  const {
    isOpen: isPopupChipsRealizeOpen,
    onOpen: onPopupChipsRealizeOpen,
    onClose: onPopupChipsRealizeClose,
  } = usePopupState()

  const { finalDrawCodes, isLoading, error, fetchFinalCodes } =
    useFinalDrawManager()

  useEffect(() => {
    if (chips > 0) {
      onPopupChipsRealizeOpen()
    }
  }, [chips])

  return (
    <GlobalStyle>
      {isWideViewport ? (
        <FinalDrawDesktop
          finalDrawCodes={finalDrawCodes}
          isLoading={isLoading}
          hasError={error}
          onRetry={fetchFinalCodes}
        />
      ) : (
        <FinalDrawMobile
          finalDrawCodes={finalDrawCodes}
          isLoading={isLoading}
          hasError={error}
          onRetry={fetchFinalCodes}
        />
      )}
      <PopupChipsRealize
        chips={chips}
        isOpened={isPopupChipsRealizeOpen}
        onClose={onPopupChipsRealizeClose}
      />
    </GlobalStyle>
  )
}
