import styled from 'styled-components'
import useMatchMedia from 'react-use-match-media'
import { useLocation, matchPath } from 'react-router-dom'

import { Box, Text, TextTitle } from '../../ui/atoms'
import { PATHS } from 'routing/paths'

const NoticeItemDisplay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 84px;
  padding: 4px;
  border-radius: 15px;
  background-color: #ffffff;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 12px;
  box-shadow: 0.886243px 2.65873px 18.61111px 0px #10575e4f;
`

const NoticeItemWrapper = styled(Box)`
  position: relative;
  margin-bottom: 40px;
`

const CaptionText = styled(TextTitle)`
  position: absolute;
  left: 50%;
  bottom: auto;
  transform: translateX(-50%);
  min-width: 91px;
  text-shadow: ${({ isWideViewport, isHome, theme }) => {
    if (isHome) {
      return isWideViewport
        ? `2px 2px ${theme.colors.textPrimaryColor}`
        : `1px 1px ${theme.colors.textTertiaryColor}`
    } else {
      return `2px 2px ${theme.colors.blue[0]}`
    }
  }};
`

export const NoticeItem = ({
  variant,
  size,
  title,
  subtitle,
  caption,
  ...props
}) => {
  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const { pathname } = useLocation()
  const isHome = !!matchPath({ path: pathname, exact: true }, PATHS.HOME)

  return (
    <NoticeItemWrapper {...props}>
      <NoticeItemDisplay variant={variant} size={size}>
        <Text
          fontSize={{ _: '40px', lg: '46px' }}
          fontWeight="700"
          lineHeight="1"
          textAlign="center"
          color="black"
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            mb="4px"
            fontSize={{ _: '14px', lg: '14px' }}
            fontWeight="700"
            lineHeight="1"
            textAlign="center"
            color="black"
          >
            {subtitle}
          </Text>
        )}
      </NoticeItemDisplay>

      <CaptionText
        fontSize={{ _: '13px', lg: '17px' }}
        fontWeight="700"
        lineHeight="100%"
        textAlign="center"
        isHome={isHome}
        isWideViewport={isWideViewport}
      >
        {caption}
      </CaptionText>
    </NoticeItemWrapper>
  )
}
