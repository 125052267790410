import React, { Fragment } from 'react'
import useMatchMedia from 'react-use-match-media'
import { useLocation, matchPath } from 'react-router-dom'

import { createGlobalStyle } from '@xstyled/styled-components'

import { fonts } from './fonts'
import { preflight } from './preflight'
import { PATHS } from 'routing/paths'

const GlobalStyleInner = createGlobalStyle`
  ${fonts}
  ${preflight}

  body {
    min-height: 100vh;

    font-family: ${props => props.theme.fonts.rubik};
    font-weight: 500;

    background-image: ${({ isMobile, isHome }) => {
      if (isMobile) {
        return 'linear-gradient(180deg,#5331DB -17%,#FD911E 61%)'
      } else if (isHome) {
        return 'radial-gradient(71% 100% at right 40% bottom 40%,rgba(253, 145, 30, 0.5) 35%, rgba(213, 118, 70, 0.5) 70% , rgba(83, 49, 219, 0.5) 105%),linear-gradient(90deg,#5331DB -5%,#884aa2 1%,#FD911E 42.61%)'
      } else {
        return 'linear-gradient(180deg, #5331DB 0%, #FD911E 75.61%)'
      }
    }}
  }
`

export const GlobalStyle = ({ children }) => {
  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const { pathname } = useLocation()
  const isHome = !!matchPath({ path: pathname, exact: true }, PATHS.HOME)

  return (
    <Fragment>
      <GlobalStyleInner isMobile={!isWideViewport} isHome={isHome} />
      {children}
    </Fragment>
  )
}
