import React from 'react'
import styled from 'styled-components'

import { Box, Container } from '../../ui/atoms'
import { NavbarMobile } from '../../ui/organisms'

const LayoutMobileWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 480px;
`

LayoutMobileWrapper.defaultProps = {
  h: '100vh',
}

const LayoutMobileBodyWrapper = styled(Box)`
  height: calc(100vh - 44px);
  overflow: auto;
`

const LayoutMobileBody = styled(Container)`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 30px;
`

const LayoutMobileHeader = styled(Box)``

const LayoutMobileContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
`

export const LayoutMobile = React.forwardRef(({ children, ...props }, ref) => (
  <LayoutMobileWrapper ref={ref} {...props}>
    <NavbarMobile />

    <LayoutMobileBodyWrapper>
      <LayoutMobileBody className="layout-mobile-body">
        {children}
      </LayoutMobileBody>
    </LayoutMobileBodyWrapper>
  </LayoutMobileWrapper>
))

LayoutMobile.Header = LayoutMobileHeader
LayoutMobile.Content = LayoutMobileContent
