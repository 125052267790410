export const fonts = `
  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-Regular'),
      local('Rubik Regular'), 
      url('/fonts/Rubik-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-Medium'),
      local('Rubik Medium'), 
      url('/fonts/Rubik-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-SemiBold'),
      local('Rubik SemiBold'), 
      url('/fonts/Rubik-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: "Rubik";
    src:
      local('Rubik-Bold'),
      local('Rubik Bold'), 
      url('/fonts/Rubik-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: "Earths Mightiest";
    src:
      local('EarthsMightiestBoldCyrillic'),
      local('Earths Mightiest Bold Cyrillic'), 
      url('/fonts/EarthsMightiestBoldCyrillic.woff2') format('woff2'),
      url('/fonts/EarthsMightiestBoldCyrillic.woff') format('woff');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
`
