import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PATHS } from '../../routing/paths'
import { getNavLinkBasedOnLocation } from '../../utils/navigation'
import { Box, Flex, Text } from '../../ui/atoms'
import styled from 'styled-components'

const NavbarWrapper = styled(Flex)`
  background-color: ${({ isMapView }) =>
    isMapView ? '#669C26' : 'transparent'};
`

export const NavbarMobile = ({ goBackURL = PATHS.HOME, navbarTitle }) => {
  const { closeUrl } = useSelector(state => state.user)
  const location = useLocation()
  const navigate = useNavigate()

  const isMapView = !!matchPath(
    { path: location.pathname, exact: true },
    PATHS.MAP_VIEW
  )
  const isHome = !!matchPath(
    { path: location.pathname, exact: true },
    PATHS.HOME
  )
  const isRulesPage = !!matchPath(
    { path: location.pathname, exact: true },
    PATHS.RULES
  )

  const onBackClick = () => {
    if (isHome) {
      if (!closeUrl) return

      window.location.href = closeUrl
    }

    if (!isHome) {
      navigate({ pathname: getNavLinkBasedOnLocation(location.pathname) })
    }
  }

  return (
    <NavbarWrapper
      justifyContent="space-between"
      minHeight="44px"
      padding="10px 12px"
      isMapView={isMapView}
    >
      <Box as="button" w="24px" h="24px" onClick={onBackClick}>
        <img src="img/arrow_back.svg" alt="" />
      </Box>

      {navbarTitle && (
        <Text
          fontSize="15px"
          lineHeight="24px"
          textAlign="center"
          letterSpacing="-0.3px"
          color="#fff"
        >
          {navbarTitle}
        </Text>
      )}

      {!isRulesPage && (
        <Flex>
          <Box as="a" href="https://vk.com/spar_kd" w="24px" h="24px" mr="16px">
            <img src="img/vk_circle_logo.svg" alt="" />
          </Box>
          <Box as="span" w="24px" h="24px">
            <Link to={PATHS.RULES}>
              <img src="img/info.svg" alt="" />
            </Link>
          </Box>
        </Flex>
      )}
    </NavbarWrapper>
  )
}
