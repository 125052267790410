import styled from 'styled-components'
import { Popup } from '../../ui/atoms'

const PopupInfoPanel = styled.div`
  position: relative;
  width: 100vw; // vw вместо % предотвращает схлопывание элемента
  max-width: 328px;
  padding-top: ${({ hasLogo }) => (hasLogo ? '80px' : '60px')};
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  margin-top: 85px;
  border-radius: 32px;
  background: radial-gradient(
    233.37% 191.83% at 50.15% 100%,
    #408d2b 0%,
    #6b9e25 59.9%
  );
`

const PopupInfoLogo = styled.img`
  position: absolute;
  top: -51px;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 254px;
`

const PopupInfoClose = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;

  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;

  background-color: transparent;
  background-image: url(img/close.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 50%;
`

export const PopupInfo = ({
  children,
  isOpened,
  onClose,
  hasLogo = true,
  hasCloseBtn,
}) => (
  <Popup isOpened={isOpened} onClose={onClose}>
    <PopupInfoPanel hasLogo={hasLogo}>
      {hasLogo && <PopupInfoLogo src={'img/popup_logo.png'} />}
      {hasCloseBtn && <PopupInfoClose onClick={onClose} />}

      {children}
    </PopupInfoPanel>
  </Popup>
)
