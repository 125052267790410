import { GlobalStyle } from 'ui/settings/global'
import { RULES_BACKGROUND } from '../../constants/backgrounds'
import { Text } from '../../ui/atoms'
import { LayoutMobile } from '../../ui/layout'

export const Rules = () => (
  <GlobalStyle>
    <LayoutMobile {...RULES_BACKGROUND} h="auto">
      <LayoutMobile.Content>
        <Text
          as="h1"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          Настоящие ПРАВИЛА стимулирующей Акции «Остров сокровищ 3»
        </Text>

        <Text mb="32px" fontSize="17px" lineHeight="22px" color="#fff">
          Настоящая стимулирующая акция под названием «Остров сокровищ 3» (далее
          по тексту - Акция) проводится согласно изложенным ниже условиям (далее
          по тексту – Правила) в рамках рекламной кампании. Данная Акция не
          является лотереей либо иной игрой, основанной на риске, не требует
          внесения платы за участие. Процедура проведения Акции не связана с
          внесением Участниками платы за принятие в ней участия, призовой фонд
          Акции сформирован за счет средств Организатора акции.
        </Text>

        <Text
          as="h2"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          1.ОБЩИЕ ПОЛОЖЕНИЯ ПРОВЕДЕНИЯ СТИМУЛИРУЮЩЕЙ АКЦИИ «Остров сокровищ 3»
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          1.1. Наименование Акции: «Остров сокровищ 3».
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          1.2. Организатор Акции: Общество с ограниченной ответственностью
          «Евроритейл» (далее по тексту настоящих Правил - «Организатор»).
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          1.3. Территория проведения Акции - Россия, Калининградская область
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          1.4. Наименование Организатора. <br />
          Организатором Акции, то есть юридическим лицом, созданным в
          соответствии с законодательством Российской Федерации, организующим
          проведение Акции непосредственно и/или через Оператора, является
          Общество с ограниченной ответственностью «Евроритейл»: <br />
          - местонахождение: 236001, Калининградская обл., г. Калининград, ул.
          Аксакова, дом № 76, к.17 <br />
          - ОГРН 1143926011316, ИНН 3906321175. <br />
          Источник информации об организаторе Акции, о правилах ее проведения,
          количество призов по результатам Акции, сроках, месте и порядке их
          получения – сайт программы лояльности Организатора в сети интернет
          friendsclub.ru и мобильное приложение Клуба Друзей.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          1.5. Генеральными партнерами Акции являются: ООО «Тетрис-Калининград»
          и ООО «Робот-Икс».
        </Text>

        <Text mb="32px" fontSize="17px" lineHeight="22px" color="#fff">
          1.6. Участники Акции: являются покупатели магазинов «SPAR» «EUROSPAR»,
          «INTERSPAR», «SPAR Express», «Семья» и «Победа», достигшие возраста 18
          лет, в городах и населенных пунктах Калининградской области, кроме
          сотрудников ООО «Евроритейл», ООО «Тетрис-Калининград» и ООО «Робот
          Икс» (в т.ч. бывших сотрудников указанных компаний, уволившихся
          позднее 3 апреля 2023 года), а также близких родственников данных
          сотрудников и сотрудников внешних аутсорсинговых компаний, которые
          обслуживают данные организации.
        </Text>

        <Text
          as="h2"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          2. СРОКИ ПРОВЕДЕНИЯ АКЦИИ
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          2.1. Общий срок проведения Акции: <br />
          Акция проводится в период с 7 августа 2023 г. по 01 октября 2023 года
          (включительно), не включая срок выдачи Призов Победителям Акции.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          2.2. Выдача уникальных номерных кодов происходит ежедневно с 10 часов
          00 минут 00 секунд 7 августа 2023 года до 23 часов 59 минут 59 секунд
          01 октября 2023 года (включительно) по местному времени в разделе
          Акции на сайте программы лояльности Организатора friendsclub.ru и в
          мобильном приложении Клуба Друзей за покупки в магазинах «SPAR»
          «EUROSPAR», «INTERSPAR», «SPAR Express», «Семья» и «Победа».
          Регистрация уникальных номерных кодов происходит ежедневно с 7 августа
          2023 года до 23 часов 59 минут 59 секунд 01 октября 2023 года
          (включительно) по местному времени в разделе Акции на сайте программы
          лояльности организатора friendsclub.ru или в мобильном приложении
          Клуба Друзей (далее по тексту – «Период регистрации Участников»).{' '}
          <br />
          <br />
          Период выдачи Призов Победителям Акции с 16 августа 2023 года по 6
          октября 2023 года по рабочим и выходным дням в период времени с 13
          часов 00 минут 00 секунд до 18 часов 00 минут 00 секунд в «Центрах
          выдачи призов» по адресам: г. Калининград, ул. Панина, 2а;
          Калининградская область, пос. Васильково, ул. Шатурская, 2;
          Калининградская область, пос. Орловка, ул. Приморское полукольцо, 1;
          г. Калининград, Люблинское шоссе, 6 (далее – «Период Выдачи Призов»).
          Адреса «Центров выдачи призов» в ходе проведения Акции могут меняться.
          Конкретное время выдачи приза заранее согласовывается с каждым
          Победителем Акции.
        </Text>

        <Text mb="32px" fontSize="17px" lineHeight="22px" color="#fff">
          2.3. Выдача электронных фишек происходит ежедневно с 10 часов 00 минут
          00 секунд 7 августа 2023 года до 23 часов 59 минут 59 секунд 01
          октября 2023 года (включительно) по местному времени в разделе Акции
          на сайте программы лояльности Организатора friendsclub.ru и в
          мобильном приложении Клуба Друзей за покупки в магазинах «SPAR»
          «EUROSPAR», «INTERSPAR», «SPAR Express», «Семья» и «Победа». <br />
          Регистрация заполненных карт (собраны и активированы все 12 фишек) и
          назначение уникальных номеров для регистрации, дающих право
          участвовать в розыгрыше одного приза первого уровня и трех призов
          второго уровня ежедневно с 7 августа 2023 года по 01 октября 2023 года
          (включительно) по местному времени в разделе Акции на сайте программы
          лояльности Организатора friendsclub.ru или в мобильном приложении
          Клуба Друзей. <br />
          <br />
          Период выдачи Призов Победителям Акции с 5 октября 2023 года по 6
          октября 2023 года в период времени с 13 часов 00 минут 00 секунд до 18
          часов 00 минут 00 секунд в «Центрах выдачи призов» по следующим
          адресам: г. Калининград, ул. Панина, 2а; Калининградская область, пос.
          Васильково, ул. Шатурская, 2; Калининградская область, пос. Орловка,
          ул. Приморское полукольцо, 1; г. Калининград, Люблинское шоссе, 6
          (далее – «Период Выдачи Призов»).
        </Text>

        <Text
          as="h2"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          3. ПРИЗОВОЙ ФОНД АКЦИИ
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          3.1. Призовой фонд Акции формируется за счет средств Организатора
          Акции и Партнеров Акции и состоит из: <br />
          Главного Приза Акции (приз первого уровня):
          <br />
          1. <b>Денежный приз в размере 1 миллион рублей</b> –1 шт (стоимость
          1 000 000 р). <br />
          Всего 1 приз первого уровня <br />
          Призы второго уровня: <br />
          1. <b>Смартфоны</b> Apple iPhone 14 128Gb (стоимость 74 490 руб.) - 27
          штук. <br />
          Всего призов второго уровня – 27 штук (далее – «Приз Акции», а все
          вместе – «Призы Акции»). <br />
          Итого 31 приз за весь период акции.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          3.2. Указанная стоимость Призов является ориентировочной,
          окончательная стоимость будет указана в документах, подписываемых с
          Победителями в период выдачи Призов.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          3.3. Организатор оставляет за собой право до даты окончания Акции
          вносить изменения в указанный в настоящем пункте перечень Призов, в
          т.ч. производить их замену на Призы иной стоимости и комплектации.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          3.4. Внешний вид, комплектация оригинального Приза может отличаться от
          его изображения в рекламных материалах.
        </Text>

        <Text mb="32px" fontSize="17px" lineHeight="22px" color="#fff">
          3.5. Об обязанности уплаты налога на доход физического лица читайте в
          п.5.2. настоящих Правил.
        </Text>

        <Text
          as="h2"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          4. УСЛОВИЯ АКЦИИ
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          4.1. Участники Акции имеют, в частности, следующие права: <br />
          право на получение информации об Акции в соответствии с настоящими
          Правилами; <br />
          право на получение Призов Акции в случае, если Участник будет признан
          выигравшим, в соответствии с настоящими Правилами; <br />
          иные права, предусмотренные настоящими Правилами и действующим
          законодательством Российской Федерации.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          4.2. Участники Акции несут, в частности, следующие обязанности: <br />
          соблюдать Правила Акции во время ее проведения; <br />
          предоставлять Организатору достоверную информацию о себе в
          соответствии с Правилами Акции; <br />
          иные обязанности, предусмотренные настоящими Правилами и действующим
          законодательством Российской Федерации.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          4.3. Чтобы принять участие в Акции, нужно: <br />
          -совершить покупку в любом магазине «SPAR», «EUROSPAR», «INTERSPAR»,
          «SPAR Express», «Семья» и «Победа» с предъявлением Карты Друга
          программы лояльности Организатора с 10:00 7 августа 2023 года до 23
          часов 59 минут 00 секунд 01 октября 2023 года (по местному времени) с
          общей суммой чека не менее 700 рублей или совершить покупку
          специального товара-спонсора Акции. <br />
          -Зарегистрировать полученный уникальный код и фишку в разделе Акции на
          сайте программы лояльности Организатора friendsclub.ru или в мобильном
          приложении Клуба Друзей.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          4.4. Электронные фишки для заполнения виртуальной карты сокровищ для
          розыгрыша приза первого уровня и уникальные коды для регистрации в
          розыгрыше трех призов второго уровня выдаются: <br />
          1. За суммарную покупку свыше 700 рублей в чеке Участник Акции
          получает 1 электронную фишку (например, 750 рублей = 1 фишка, 1400
          рублей = 1 фишка) и уникальный код для еженедельного розыгрыша Акции
          на сайте программы лояльности организатора friendsclub.ru или в
          мобильном приложении Клуба Друзей. <br />
          2. За покупку и наличие в чеке специального товара-спонсора. <br />
          3. При любом заказе в интернет-магазине SPAR ONLINE на сайте
          spar-online.ru или через мобильное приложение «Клуба Друзей» Участник
          акции получает 3 электронные фишки и уникальный код для еженедельного
          розыгрыша в разделе Акции на сайте программы лояльности Организатора
          friendsclub.ru или в мобильном приложении Клуба Друзей. <br />
          4. При любой покупке от 700 рублей в магазинах SPAR и «Семья»
          Калининградской области с помощью платёжного сервиса KD pay Участник
          Акции получает 3 дополнительные электронные фишки в разделе Акции на
          сайте программы лояльности Организатора friendsclub.ru или в мобильном
          приложении Клуба Друзей. Дополнительные фишки начисляются по итогам
          прошедшей недели не позднее следующего за этой неделей понедельника.
          Каждый Участник Акции может получить не более 3х дополнительных фишек
          в неделю за все покупки с KD pay. <br />
          <br />
          Каждый Участник Акции может совершить не более 7 покупок в день с
          применением одной Карты Друга.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          4.5. Для участия в розыгрыше приза первого уровня и трех призов
          второго уровня необходимо собрать заданное количество фишек
          (двенадцать), которые «вклеиваются» в электронную карту сокровищ в
          разделе «Финальный розыгрыш» Акции на сайте программы лояльности
          Организатора friendsclub.ru или в мобильном приложении Клуба Друзей.{' '}
          <br />
          При заполнении карты последней двенадцатой фишкой Участнику Акции
          автоматически присваивается восьмизначный уникальный код для
          финального розыгрыша. <br />
          <br />
          Участник акции должен «вклеить» все фишки в электронную карту сокровищ
          для получения уникального восьмизначного кода финального розыгрыша в
          разделе «Финальный розыгрыш» Акции на сайте программы лояльности
          Организатора friendsclub.ru или в мобильном приложении Клуба Друзей до
          23:59 01 октября 2023г. <br />
          <br />
          Электронная фишка выдается в расчете от итоговой суммы Чека, после
          учета всех скидок или за наличие в чеке специального товара-спонсора.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          4.6. Один Участник Акции (покупатель, зарегистрированный в Программе
          лояльности Организатора «Клуб Друзей» и указавший номер своего
          мобильного телефона) может зарегистрировать не более 30 уникальных
          восьмизначных кодов для участия в финальном розыгрыше за текущий
          период акции. <br />
          <br />
          При смене номера телефона, с помощью которого Участник Акции был
          зарегистрирован в программе лояльности «Клуб Друзей», все выпущенные
          ранее для этого номера уникальные восьмизначные коды сгорают и не
          подлежат восстановлению. <br />
          <br />
          Подозрительные активности будут удалены из общей базы участников, без
          предупреждения самих участников.
        </Text>

        <Text mb="32px" fontSize="17px" lineHeight="22px" color="#fff">
          4.7. За каждый товар-спонсора покупатель получает электронную фишку
          для «вклеивания» в виртуальную карту и уникальный код для
          еженедельного розыгрыша. Если в чеке 30 разных товаров-спонсоров, то
          покупатель получает дополнительно 30 электронных фишек и 30 уникальных
          кодов для еженедельного розыгрыша. Однако количество одинаковых
          товаров-спонсоров, за которые покупатель может получить дополнительные
          электронные фишки для «вклеивания» в виртуальную карту и уникальные
          коды для еженедельного розыгрыша, не может превышать 5 штук в одном
          чеке. Например, при наличии 5 одинаковых товаров-спонсоров в одной
          покупке — покупатель получит 5 фишек и 5 кодов, а за покупку 10 таких
          же товаров он получит 5 фишек и кодов.
        </Text>

        <Text
          as="h2"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          5. ПОРЯДОК ОПРЕДЕЛЕНИЯ ПОБЕДИТЕЛЯ АКЦИИ И ВЫДАЧИ ПРИЗОВ
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.1. Для определения Победителей Акции Организатор создает экспертную
          комиссию, состоящую из трех человек. Члены комиссии – это граждане РФ,
          совершеннолетние, дееспособные и правоспособные. <br />- Один
          представитель от Организатора Акции для контроля за проведением
          процедуры определения победителей Акции и соответствия ее Правилам.{' '}
          <br />- Один председатель комиссии: представитель от Организатора
          Акции для предоставления реестра всех зарегистрированных чеков в Акции
          на мероприятии, по определению победителей Акции. Контроль за
          проведением процедуры определения победителей Акции и соответствия ее
          Правилам участия.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.2. Период определения Победителей Акции происходит этапами: <br />
          Розыгрыш 27 ценных призов Второго уровня проходит согласно графику:{' '}
          <br />
          <br />
          1. период регистрации с 7 августа по 13 августа 2023 года до 23 часов
          59 минут - дата определения победителей 15 августа 2023 года; <br />
          2. период регистрации с 14 августа по 20 августа 2023 года до 23 часов
          59 минут - дата определения победителей 22 августа 2023 года; <br />
          3. период регистрации с 21 августа по 27 августа 2023 года до 23 часов
          59 минут - дата определения победителей 29 августа 2023 года; <br />
          4. период регистрации с 28 августа по 3 сентября 2023 года до 23 часов
          59 минут - дата определения победителей 5 сентября 2023 года; <br />
          5. период регистрации с 4 сентября по 10 сентября 2023 года до 23
          часов 59 минут - дата определения победителей 12 сентября 2023 года.{' '}
          <br />
          6. период регистрации с 11 сентября по 17 сентября 2023 года до 23
          часов 59 минут - дата определения победителей 19 сентября 2022 года;{' '}
          <br />
          7. период регистрации с 18 сентября по 24 сентября 2023 года до 23
          часов 59 минут - дата определения победителей 26 сентября 2023 года.{' '}
          <br />
          8. период регистрации с 25 сентября по 1 октября 2023 года до 23 часов
          59 минут - дата определения победителей 4 октября 2023 года. <br />
          <br />
          В розыгрышах принимают участие коды, выданные и зарегистрированные в
          указанные периоды. Не допускается к участию в розыгрыше коды, выданные
          ранее указанного периода регистрации.
          <br />
          <br />
          Розыгрыш ценных призов – одного приза Первого и трех призов Второго
          уровня проходит согласно графику:
          <br />
          период регистрации с 7 августа 2023 года по 01 октября 2023 года до 23
          часов 59 минут – дата определения победителей 4 октября 2023 года.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.3. Определение будет проводиться путем случайного выпадения номеров
          победителей с помощью специализированного программного обеспечения,
          имеющего сертификат соответствия ПО, используемого для случайного
          выбора единичных данных из баз данных и прочих массивов информации в
          едином регистре. По итогам каждого розыгрыша на сайте Акции будут
          публиковаться выигрышные уникальные номерные кода и последние 4 цифры
          номера телефона.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.4. Участник, выигравший один из перечисленных призов в текущем
          розыгрыше, не может претендовать на такой же приз (данной категории) в
          последующих розыгрышах. Все уникальные номерные кода, не выигравшие в
          текущем розыгрыше, аннулируются и не принимают участие в следующем.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.5. Призы не вручаются Победителям по следующим причинам:
          <br />
          - победитель отказался от приза или подписания Акта приемки-передачи
          или иных документов, связанных с вручением призов; <br />- организатор
          не смог связаться с Участником и уведомить его о победе, а Участник не
          ознакомился с итогами и не вышел на связь с Организатором в течение
          24-х часов после подведения итогов розыгрыша; <br />
          - победитель не смог приехать в центр выдачи призов в течении двух
          суток с момента определения победителей розыгрыша или не смог прислать
          своих делегатов с доверенностью на получение приза; <br />
          - победитель не выполнил какие-либо действия, необходимые для
          получения Приза и предусмотренные настоящим Положением либо совершил
          их с нарушением установленного срока; <br />
          - при проверке документов установлено, что Участник представил о себе
          неполную, искаженную или недостоверную информацию; <br />
          <br />
          Организатор не несет ответственности за правильность заполнения
          Участником его контактных данных при регистрации в программе
          лояльности «Клуб Друзей».
          <br />
          <br />
          Организатор вправе отказать в участии любому лицу без объяснения
          причин, если есть основания полагать, что такой Участник совершил
          неправомерные действия, которые повлияли на результат Акции или
          нарушил иные условия данного Положения.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.6. Организатор не несет ответственности в случае, если Победитель не
          явится в указанное время и дату для получения приза. В таком случае
          приз не вручается и остается у Организатора Акции и используется по
          своему усмотрению.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.7. Итоги акции будут опубликованы в разделе Акции на сайте программы
          лояльности Организатора friendsclub.ru, а также на официальном
          аккаунте Организатора «SPAR Калининград» в социальной сети «Вконтакте»
          в течение 24-часов со дня проведения мероприятия по определению
          победителей. За исключением розыгрыша приза первого уровня, где период
          подготовки публикации составляет 72 часа.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.8. В течение 24-х часов со дня проведения Мероприятия по определению
          победителей Организатор связывается с Победителями первой категории
          призов по номеру телефона, указанному при регистрации в программе
          лояльности «Клуб Друзей» и сообщает ему о его выигрыше. За исключением
          розыгрыша приза первого уровня, где период для связи с покупателем
          составляет 72 часа.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.9. Призы могут отличаться по внешнему виду от их изображений на
          рекламно-информационных материалах.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.10. Ответственность Организатора Акции и Партнера Акции по выдаче
          призов ограничена исключительно вышеуказанным количеством и
          характеристиками. Все претензии относительно качества призов
          необходимо предъявлять непосредственно товаропроизводителю.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.11. Договор между Организатором и Участником Акции является
          безвозмездным, т.е. потенциальный Участник не вносит отдельной платы
          за участие в ней.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.12. Факт регистрации присвоенного уникального кода на сайте
          программы лояльности Организатора friendsclub.ru или в мобильном
          приложении Клуба Друзей подразумевает, что данный Участник Акции
          ознакомлен и полностью согласен с настоящим Положением о проведении
          стимулирующей Акции, согласен на сбор обработку, хранение,
          Организатором и уполномоченными им лицами персональных данных
          Участника Акции, согласен на рассылку SMS рекламы, рекламы данной
          Акции, а также любой информации касающейся Акции. Кроме того, факт
          регистрации присвоенного уникального кода на сайте программы
          лояльности Организатора friendsclub.ru или в мобильном приложении
          Клуба Друзей подтверждает согласие участника Акции в случае победы в
          одном из розыгрышей на трансляцию в прямом эфире аудио-сигнала
          телефонного звонка, на указанный при регистрации в программе
          лояльности номер мобильного телефона, представителя организатора Акции
          с целью информирования Участника о выпадении его номера, в социальных
          сетях организатора Акции и его партнеров.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.13. Обязанности по исчислению и уплате налогов, связанных с
          получением призов, а также ответственность за неисполнение этой
          обязанности Участники Акции (Победители) несут самостоятельно.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.14. Приз вручается в следующем порядке: <br />
          Победителям, в течение 2-х рабочих дней с даты публикации итогов и
          оповещения необходимо: <br />- предоставить по адресу г. Калининград,
          Люблинское шоссе, 6, офис 207 <br />
          следующую информацию о себе: <br />
          - ФИО; <br />
          - почтовый адрес; <br />
          - копию страниц своего паспорта РФ (разворот с фотографией, страница с
          информацией о действующей прописке); <br />
          - копию свидетельства ИНН; <br />
          - копию СНИЛС; <br />
          - иную информация по запросу Организатора; <br />- наличие покупки, за
          которую были выданы уникальные коды и электронные фишки в разделе
          «История дружбы» приложения «Клуба Друзей».
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          5.15. После получения от Участника информации, указанной в п.4.14.
          Положения, Организатор согласовывает дату, время и место вручения
          Приза.
        </Text>

        <Text mb="32px" fontSize="17px" lineHeight="22px" color="#fff">
          5.16. Победитель обязан подписать Акт приемки-передачи приза и иные
          документы, связанные с передачей ему приза.
        </Text>

        <Text
          as="h2"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          6. ПРАВА И ОБЯЗАННОСТИ УЧАСТНИКОВ АКЦИИ
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          6.1. Участник Акции вправе требовать от Организатора Акции: <br />-
          получения информации об Акции в соответствии с правилами Акции; <br />
          - при соблюдении всех условий данного Положения, выдачи приза.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          6.2. Организатор настоящим Положением информируют Участника о том, что
          в соответствии с положениями Налогового Кодекса РФ стоимость Призов,
          полученных от организации превышающая 4 000 руб. за отчетный период
          (календарный год), в том числе полученных в натуральной форме,
          включается в налоговую базу по НДФЛ. Принимая участие в Акции и
          соглашаясь с настоящими правилами, Участники, в том числе Победители,
          считаются надлежащим образом проинформированными о вышеуказанной норме
          налогового законодательства РФ. Победитель обязан уплатить
          соответствующий налог на доходы физических лиц (НДФЛ) по ставке&nbsp;
          <b>35%</b>.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          6.3. Участник Акции обязуется указывать точные, актуальные и
          достоверные данные при регистрации в программе лояльности «Клуб
          Друзей» согласно п. 2 настоящего Положения.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          6.4. Сумма НДФЛ составляет <b>35%</b> от стоимости приза, указанной в
          акте-передачи приза.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          6.5. Организатор подает сведения в налоговую инспекцию в виде справки
          2-НДФЛ, о возможности удержания налога, т.к. приз будет выдан в
          натуральной стоимости.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          6.6. Покупатель должен самостоятельно уплатить НДФЛ от стоимости приза
          на официальном сайте Федеральной налоговой службы или через Единый
          портал государственных услуг.
        </Text>

        <Text mb="32px" fontSize="17px" lineHeight="22px" color="#fff">
          6.7. Принимая приз, покупатель даёт согласие на проведение съемки с
          его участием в момент передачи приза и публикацию фото и видео
          материалов с его изображением в социальных сетях и других рекламных
          материалах Организации.
        </Text>

        <Text
          as="h2"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          7. ПРАВА, ОБЯЗАННОСТИ И ОТВЕТСТВЕННОСТЬ ОРГАНИЗАТОРА
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.1. Организатор обязуется провести Акцию при технической поддержке,
          осуществляемой сайтом программы лояльности «Клуб Друзей»
          friendsclub.ru и мобильным приложением Клуба Друзей; а также
          обеспечить получение участниками призов в соответствии с условиями
          Акции.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.2. Организатор не несет ответственность за неисполнение либо
          ненадлежащие исполнение своих обязательств, а также за какие-либо
          прямые, косвенные, особые потери Участников, связанные с участием в
          данной Акции, если неисполнение обязательств явилось следствием
          непредвидимых обстоятельств непреодолимой силы. Организатор не обязан
          возмещать потери участникам Акции в подобных случаях.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.3. Организатор имеет право отстранить Участника от участия в Акции
          на любом этапе проведения Акции, если возникли подозрения, что
          Участник (или кто-то другой за него) в ходе Акции нарушает условия
          акции или пытается изменить ее результаты посредством технических,
          программных или других средств, кроме способов, описанных в правилах
          проведения Акции.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.4. Организатор не несет ответственности за любой ущерб, который
          возможно понесет Победитель вследствие использования им призов и/или
          участия в Акции.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.5. Организатор имеет право отказать Победителю в предоставление
          призов, если Победитель предоставил о себе неверную информацию.
          Предоставил ее несвоевременно или каким-либо другим образом нарушил
          настоящие правила проведения Акции. В случае проверки Победителя или
          участника акции на соблюдения условий акции Организатор вправе
          потребовать от Победителя или участника акции доказательства
          совершения покупок в магазинах участниках акции (выписки с банковских
          карт, копии чеков и т.д.). Если Победитель или участник акции не может
          доказать факт покупки (соблюдения условий акции) Организатор вправе
          отказать в получении приза и переиграть приз, а также признать факт
          нарушения Участником условий акции.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.6. Организатор не несет ответственности за непредоставление
          Участником необходимых сведений для получения Приза, в том числе по
          вине почтовой службы, организации связи. За технические проблемы и/или
          мошенничества в сети Интернет и/или каналов связи, используемых при
          проведении Акции, а также за невозможность осуществления связи с
          Участником из-за указанных неверных или неактуальных контактных
          данных, в том числе номера телефона. ФИО участника и иных данных, а
          также технических проблем, возникших в момент регистрации в программе
          лояльности или пробития чека в магазине.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.7. Организатор Акции не несет ответственность за технические
          неполадки на сайте программы лояльности «Клуб Друзей» friendsclub.ru и
          мобильном приложении «Клуб Друзей», в результате которых может
          возникнуть сбой в их работе.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.8. Организатор Акции оставляет за собой право не вступать в
          письменные переговоры либо иные контакты с Участниками Акции, кроме
          случаев, предусмотренных настоящим Положением, действующим
          законодательством РФ.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.9. Организатор Акции имеет право на свое усмотрение в одностороннем
          порядке прекратить проведение Акции или изменить её условия, если по
          какой-то причине любой аспект настоящей Акции не может проводиться
          так, как это запланировано.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          7.10. Данное Положение является единственными официальными правилами
          участия в Акции. В случае возникновения ситуаций, допускающих
          неоднозначное толкование данных правил, окончательное решение о таком
          толковании принимается исключительно Организатором Акции в
          соответствии с законодательством РФ.
        </Text>

        <Text mb="32px" fontSize="17px" lineHeight="22px" color="#fff">
          7.11. Организатор имеет право сделать фото- и видео-сессию Победителя
          Акции с Призом и разместить эту информацию в СМИ, на сайте программы
          лояльности «Клуб Друзей» friendsclub.ru и в мобильном приложении «Клуб
          Друзей», а также во всех социальных сетях компании.{' '}
        </Text>

        <Text
          as="h2"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          8. ПЕРСОНАЛЬНЫЕ ДАННЫЕ
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          8.1. Принимая участие в Акции и добровольно предоставляя свои
          персональные данные, Участник подтверждает свое согласие на обработку
          Организатором Акции предоставленных данных, включая сбор, запись,
          систематизацию, накопление, использование, хранение, уточнение,
          обновление, изменение, извлечение, использование, передачу
          распространение, обезличивание, блокирование, удаление, уничтожение
          для целей проведения Акции на весь срок ее проведения, в соответствии
          с положениями предусмотренными Федеральным законом РФ № 152-ФЗ от 27
          июля 2006г. «О персональных данных (далее – Закон).
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          8.2. Участники понимают и соглашаются с тем, что персональные данные,
          указанные/предоставленные ими для участия в Акции, будут
          обрабатываться всеми необходимыми способами в целях проведения Акции,
          иными целями в соответствии с настоящим Положением, и дают свое
          согласие на такую обработку.
        </Text>

        <Text mb="32px" fontSize="17px" lineHeight="22px" color="#fff">
          8.3. Принимая участие в Акции, Участники подтверждают свое согласие
          тем, что Организатор и уполномоченные им лица, которые будут соблюдать
          необходимые меры защиты таких данных от несанкционированного
          распространения, вправе осуществлять сбор, систематизацию, хранение, а
          также иным способом обрабатывать персональные данные Участника,
          который Участник предоставляет по запросу Организатора Акции-данные
          паспорта гражданина РФ: <br />
          - ФИО; <br />
          - дата и место рождения; <br />
          - серия и номер паспорта, дата выдачи. Наименование выдавшего органа,
          код подразделения; <br />
          - адрес регистрации по месту жительства; <br />
          - номер ИНН. <br />
          Вышеперечисленные персональные данные получаются и обрабатываются
          Организатором исключительно в целях, проведения настоящей Акции, для
          выдачи приза в случае выигрыша. <br />
          <br />
          Согласие действительно с момента регистрации уникального кода на сайте
          программы лояльности «Клуб Друзей» friendsclub.ru или в мобильном
          приложении «Клуб Друзей». <br />
          <br />
          Персональные данные Участника хранятся в базе Организатора в течение
          срока проведения Акции. Субъект персональных данных вправе отозвать
          свое согласие, отправив электронное письмо Организатору.
        </Text>

        <Text
          as="h2"
          mb="32px"
          fontSize="24px"
          fontWeight="600"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          9. ПРОЧЕЕ
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          9.1. Любое время, указанное в настоящих Правилах, считается
          по-местному Калининградскому времени.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          9.2. Во всех ситуациях, не предусмотренным настоящим Положением,
          Организатор и Участники руководствуются действующим законодательством
          РФ.
        </Text>

        <Text mb="16px" fontSize="17px" lineHeight="22px" color="#fff">
          9.3. Стороны освобождаются от ответственности за невыполнение или
          ненадлежащие выполнение своих обязательств, если такое невыполнение
          явилось результатом действия непреодолимой силы.
        </Text>
      </LayoutMobile.Content>
    </LayoutMobile>
  </GlobalStyle>
)
