export const MAPS_POINTS_POSITION = {
  1: [
    {
      id: 1,
      position: {
        mobile: { top: '82.75%', left: '65.35%' },
        desktop: { top: '28.6%', left: '9.6%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { top: '76.65%', left: '70.9%' },
        desktop: { top: '31.95%', left: '22.8%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { top: '71.25%', left: '45.65%' },
        desktop: { top: '42.9%', left: '11.9%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { top: '68.68%', left: '16.3%' },
        desktop: { top: '57.7%', left: '21.65%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { top: '60.84%', left: '16.55%' },
        desktop: { top: '76.16%', left: '30.65%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { top: '59.75%', left: '51.95%' },
        desktop: { top: '68.76%', left: '42.33%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { top: '53.2%', left: '71.3%' },
        desktop: { top: '51.06%', left: '54.05%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { top: '42.15%', left: '58.5%' },
        desktop: { top: '24.35%', left: '50.75%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { top: '40.15%', left: '18.35%' },
        desktop: { top: '29.68%', left: '64.45%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { top: '31.95%', left: '45.95%' },
        desktop: { top: '58.06%', left: '78.5%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { top: '25.69%', left: '50.7%' },
        desktop: { top: '57.6%', left: '62.9%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { top: '21.3%', left: '17.7%' },
        desktop: { top: '75.5%', left: '71.85%' },
      },
    },
  ],

  2: [
    {
      id: 1,
      position: {
        mobile: { top: '87.65%', left: '71.25%' },
        desktop: { top: '20%', left: '22.55%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { top: '77.55%', left: '71%' },
        desktop: { top: '19.7%', left: '39.1%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { top: '78.2%', left: '34.2%' },
        desktop: { top: '20.77%', left: '51.65%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { top: '70.4%', left: '26.8%' },
        desktop: { top: '36.2%', left: '34.95%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { top: '65.25%', left: '68.15%' },
        desktop: { top: '49.1%', left: '22.35%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { top: '55.55%', left: '69.3%' },
        desktop: { top: '48.2%', left: '38.3%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { top: '51.07%', left: '36.2%' },
        desktop: { top: '72.25%', left: '41.35%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { top: '43.87%', left: '65%' },
        desktop: { top: '52.9%', left: '47.65%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { top: '35.97%', left: '38.5%' },
        desktop: { top: '49.4%', left: '59.2%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { top: '30.65%', left: '66.9%' },
        desktop: { top: '62.8%', left: '70.75%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { top: '24.25%', left: '56.25%' },
        desktop: { top: '54.7%', left: '77.45%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { top: '17.87%', left: '53.3%' },
        desktop: { top: '36.1%', left: '76.8%' },
      },
    },
  ],
  3: [
    {
      id: 1,
      position: {
        mobile: { top: '83.8%', left: '64.15%' },
        desktop: { top: '18.1%', left: '11%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { top: '82.65%', left: '21.5%' },
        desktop: { top: '43.6%', left: '18.28%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { top: '70.65%', left: '17.9%' },
        desktop: { top: '25.4%', left: '29.25%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { top: '64.47%', left: '46.83%' },
        desktop: { top: '57%', left: '34.45%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { top: '68.25%', left: '71.3%' },
        desktop: { top: '75.5%', left: '43.05%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { top: '57.68%', left: '46.5%' },
        desktop: { top: '36.5%', left: '63.6%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { top: '48.7%', left: '32.4%' },
        desktop: { top: '33.35%', left: '76.25%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { top: '34.3%', left: '68.6%' },
        desktop: { top: '23.5%', left: '56.8%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { top: '27.83%', left: '41.8%' },
        desktop: { top: '51.4%', left: '48.15%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { top: '18.9%', left: '71%' },
        desktop: { top: '77.3%', left: '60.75%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { top: '12.25%', left: '53.1%' },
        desktop: { top: '59.2%', left: '72.8%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { top: '3.76%', left: '47.15%' },
        desktop: { top: '49.2%', left: '79.7%' },
      },
    },
  ],
}

export const WINTER_MAPS_POINTS_POSITION = {
  1: [
    {
      id: 1,
      position: {
        mobile: { top: '84.25%', left: '64.85%' },
        desktop: { top: '28.6%', left: '9.6%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { top: '77.35%', left: '70.4%' },
        desktop: { top: '31.95%', left: '22.8%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { top: '71.45%', left: '45.45%' },
        desktop: { top: '42.9%', left: '11.9%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { top: '68.58%', left: '16.3%' },
        desktop: { top: '57.7%', left: '21.65%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { top: '59.84%', left: '16.55%' },
        desktop: { top: '76.16%', left: '30.65%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { top: '58.55%', left: '51.65%' },
        desktop: { top: '68.76%', left: '42.33%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { top: '51.3%', left: '70.8%' },
        desktop: { top: '51.06%', left: '54.05%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { top: '39.05%', left: '58.1%' },
        desktop: { top: '24.35%', left: '50.75%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { top: '36.85%', left: '18.35%' },
        desktop: { top: '29.68%', left: '64.45%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { top: '27.75%', left: '45.75%' },
        desktop: { top: '58.06%', left: '78.5%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { top: '20.69%', left: '50.4%' },
        desktop: { top: '57.6%', left: '62.9%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { top: '15.9%', left: '17.7%' },
        desktop: { top: '75.5%', left: '71.85%' },
      },
    },
  ],

  2: [
    {
      id: 1,
      position: {
        mobile: { top: '87.05%', left: '71.25%' },
        desktop: { top: '20%', left: '22.55%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { top: '76.55%', left: '73.1%' },
        desktop: { top: '19.7%', left: '39.1%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { top: '77.6%', left: '34.2%' },
        desktop: { top: '20.77%', left: '51.65%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { top: '69.85%', left: '26.8%' },
        desktop: { top: '36.2%', left: '34.95%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { top: '64.65%', left: '68.15%' },
        desktop: { top: '49.1%', left: '22.35%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { top: '54.95%', left: '69.3%' },
        desktop: { top: '48.2%', left: '38.3%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { top: '50.47%', left: '36.2%' },
        desktop: { top: '72.25%', left: '41.35%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { top: '43.27%', left: '65%' },
        desktop: { top: '52.9%', left: '47.65%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { top: '35.37%', left: '38.5%' },
        desktop: { top: '49.4%', left: '59.2%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { top: '30.05%', left: '66.9%' },
        desktop: { top: '62.8%', left: '70.75%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { top: '23.65%', left: '56.25%' },
        desktop: { top: '54.7%', left: '77.45%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { top: '17.28%', left: '53.3%' },
        desktop: { top: '36.1%', left: '76.8%' },
      },
    },
  ],
  3: [
    {
      id: 1,
      position: {
        mobile: { top: '84.7%', left: '64.15%' },
        desktop: { top: '18.1%', left: '11%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { top: '83.55%', left: '21.5%' },
        desktop: { top: '43.6%', left: '18.28%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { top: '71.55%', left: '17.9%' },
        desktop: { top: '25.4%', left: '29.25%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { top: '65.37%', left: '46.83%' },
        desktop: { top: '57%', left: '34.45%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { top: '69.18%', left: '71.3%' },
        desktop: { top: '75.5%', left: '43.05%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { top: '58.58%', left: '46.5%' },
        desktop: { top: '36.5%', left: '63.6%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { top: '49.6%', left: '32.4%' },
        desktop: { top: '33.35%', left: '76.25%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { top: '35.2%', left: '68.6%' },
        desktop: { top: '23.5%', left: '56.8%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { top: '28.73%', left: '41.8%' },
        desktop: { top: '51.4%', left: '48.15%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { top: '19.8%', left: '71%' },
        desktop: { top: '77.3%', left: '60.75%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { top: '13.15%', left: '53.1%' },
        desktop: { top: '59.2%', left: '72.8%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { top: '4.66%', left: '47.15%' },
        desktop: { top: '49.2%', left: '79.7%' },
      },
    },
  ],
}

export const SUMMER_MAPS_POINTS_POSITION = {
  1: [
    {
      id: 1,
      position: {
        mobile: { top: '84.44%', left: '65.53%' },
        desktop: { top: '44.7%', left: '9.2%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { top: '77.5%', left: '62.4%' },
        desktop: { top: '67.35%', left: '12.2%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { top: '75.45%', left: '23.45%' },
        desktop: { top: '69.7%', left: '38.95%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { top: '68.8%', left: '27.3%' },
        desktop: { top: '47.8%', left: '38.1%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { top: '64%', left: '57.5%' },
        desktop: { top: '44.5%', left: '25.8%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { top: '56.8%', left: '60.45%' },
        desktop: { top: '30.85%', left: '22.7%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { top: '51.8%', left: '29.4%' },
        desktop: { top: '35.9%', left: '39.4%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { top: '40.9%', left: '61.5%' },
        desktop: { top: '36.7%', left: '54.1%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { top: '34.65%', left: '67.35%' },
        desktop: { top: '47.8%', left: '65.85%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { top: '29.25%', left: '73.35%' },
        desktop: { top: '63.3%', left: '57%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { top: '25.1%', left: '36.05%' },
        desktop: { top: '65.9%', left: '68.3%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { top: '17.2%', left: '49.9%' },
        desktop: { top: '72.75%', left: '79.5%' },
      },
    },
  ],

  2: [
    {
      id: 1,
      position: {
        mobile: { top: '86.63%', left: '35.1%' },
        desktop: { top: '28.3%', left: '10.7%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { top: '79.6%', left: '20.2%' },
        desktop: { top: '49.3%', left: '13.7%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { top: '74%', left: '55%' },
        desktop: { top: '59.65%', left: '27.25%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { top: '67.4%', left: '18.4%' },
        desktop: { top: '30.35%', left: '21.35%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { top: '62.5%', left: '66%' },
        desktop: { top: '14.05%', left: '33.45%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { top: '53.95%', left: '31.3%' },
        desktop: { top: '35.6%', left: '38%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { top: '49.4%', left: '53.5%' },
        desktop: { top: '62.8%', left: '37.8%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { top: '41.2%', left: '27.6%' },
        desktop: { top: '67.1%', left: '59.35%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { top: '34.2%', left: '63.9%' },
        desktop: { top: '40.7%', left: '50.55%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { top: '28.75%', left: '26.9%' },
        desktop: { top: '38.3%', left: '65.4%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { top: '20.65%', left: '20.95%' },
        desktop: { top: '56.65%', left: '75.1%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { top: '15.5%', left: '55.8%' },
        desktop: { top: '44.57%', left: '86.8%' },
      },
    },
  ],
  3: [
    {
      id: 1,
      position: {
        mobile: { top: '86.3%', left: '64.65%' },
        desktop: { top: '56.5%', left: '11.05%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { top: '77.75%', left: '66.5%' },
        desktop: { top: '37.7%', left: '24.1%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { top: '74.55%', left: '18.15%' },
        desktop: { top: '14.25%', left: '30.7%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { top: '66.9%', left: '45%' },
        desktop: { top: '30%', left: '44%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { top: '60.45%', left: '66%' },
        desktop: { top: '48%', left: '32.85%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { top: '53.4%', left: '63.5%' },
        desktop: { top: '75.3%', left: '31.19%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { top: '50.4%', left: '31.4%' },
        desktop: { top: '70.15%', left: '43.85%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { top: '44.2%', left: '69.9%' },
        desktop: { top: '54.35%', left: '54.3%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { top: '40%', left: '20.6%' },
        desktop: { top: '30.75%', left: '57.8%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { top: '31.4%', left: '43.8%' },
        desktop: { top: '55.15%', left: '64.65%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { top: '24.7%', left: '72.05%' },
        desktop: { top: '34.87%', left: '71.2%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { top: '17.25%', left: '44.15%' },
        desktop: { top: '44.7%', left: '78.7%' },
      },
    },
  ],
}

export const MAP_STATUS = {
  ACTIVE: 1,
  COMPLETED: 2,
}
