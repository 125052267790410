import React from 'react'
import styled from 'styled-components'

import { MAPS_DESKTOP_BACKGROUND } from '../../constants/backgrounds'
import { Flex, TextTitle } from '../../ui/atoms'
import { ChipsInfo } from '../../ui/molecules'
import { LayoutDesktop } from '../../ui/layout'
import { MapList } from '../../ui/organisms'

const TextTitleMaps = styled(TextTitle)`
  text-shadow: ${({ theme }) => `4px 4px ${theme.colors.textSecondaryColor}`};
`

export const MapsDesktop = React.forwardRef(
  (
    {
      maps,
      isLoading,
      hasError,
      onRetry,
      isWideViewport,
      mapListTopOffset,
      mapListItemWidth,
    },
    ref
  ) => (
    <LayoutDesktop ref={ref} {...MAPS_DESKTOP_BACKGROUND}>
      <TextTitleMaps
        fontSize="56px"
        fontWeight="500"
        lineHeight="130%"
        letterSpacing="0.5px"
        textAlign="center"
        mx="auto"
        mb="41px"
      >
        Мои карты
      </TextTitleMaps>

      <Flex
        position="relative"
        w="100%"
        maxWidth="568px"
        mx="auto"
        minHeight="0"
        minWidth="0"
        flexGrow="1"
        flexDirection="column"
      >
        <ChipsInfo
          mb="18px"
          ml="12px"
          isLoading={isLoading}
          hasError={hasError}
          onRetry={onRetry}
        />

        {!isLoading && !hasError && (
          <MapList
            maps={maps}
            isWideViewport={isWideViewport}
            topOffset={mapListTopOffset}
            listItemWidth={mapListItemWidth}
          />
        )}
      </Flex>
    </LayoutDesktop>
  )
)
