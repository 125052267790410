import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PATHS } from '../../routing/paths'
import { HOME_DESKTOP_BACKGROUND } from '../../constants/backgrounds'
import { Box, Button } from '../../ui/atoms'
import { LayoutDesktop } from '../../ui/layout'
import { Notice } from '../../ui/organisms'
import { TextTitle } from 'ui/atoms/TextTitle'

const TextTitleHome = styled(TextTitle)`
  text-shadow: ${({ theme }) => `4px 4px ${theme.colors.textTertiaryColor}`};
`
const TextSubtitleHome = styled(TextTitle)`
  text-transform: none;
`

export const HomeDesktop = () => (
  <LayoutDesktop {...HOME_DESKTOP_BACKGROUND}>
    <Notice mt="50px" mb="35px" />

    <Box alt="Акция завершена" mb="32px" maxWidth="550px">
      <TextTitleHome fontSize="58px">Акция</TextTitleHome>
      <TextTitleHome fontSize="58px">завершена!</TextTitleHome>
    </Box>

    <Box alt="До встречи в новых играх" maxWidth="550px">
      <TextSubtitleHome fontSize="38px">
        До встречи в новых играх!
      </TextSubtitleHome>
    </Box>
  </LayoutDesktop>
)
