import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PATHS } from '../../routing/paths'
import { FINAL_DRAW_DESKTOP_BACKGROUND } from '../../constants/backgrounds'
import { FINAL_DRAW_NO_CODES_MSG } from '../../constants/messages'
import { Box, Button, TextTitle } from '../../ui/atoms'
import { LayoutDesktop } from '../../ui/layout'
import { CodeList } from '../../ui/organisms'

const TextTitleFinalDraw = styled(TextTitle)`
  text-shadow: ${({ theme }) => `3px 3px ${theme.colors.blue[0]}`};
`

export const FinalDrawDesktop = ({
  finalDrawCodes,
  isLoading,
  hasError,
  onRetry,
}) => (
  <LayoutDesktop {...FINAL_DRAW_DESKTOP_BACKGROUND}>
    <TextTitle
      fontSize="24px"
      lineHeight="18px"
      textAlign="center"
      mx="auto"
      mb="12px"
    >
      4 октября
    </TextTitle>

    <Box w="440px" mx="auto">
      <TextTitleFinalDraw
        fontSize="56px"
        fontWeight="500"
        letterSpacing="0.5px"
        textAlign="center"
      >
        Финальный розыгрыш
      </TextTitleFinalDraw>
    </Box>

    <Box
      as="img"
      src="img/million-of-rubles-title.svg"
      alt="Приз в 1000000 рублей"
      w="375px"
      mt="-6px"
      mx="auto"
      mb="10px"
    />

    <TextTitle mb="20px" fontSize="24px" textAlign="center">
      Мои коды
    </TextTitle>

    <Box
      w="100%"
      maxWidth="588px"
      mx="auto"
      minHeight="0"
      minWidth="0"
      style={{ flexGrow: 1 }}
    >
      <CodeList
        isWideScreen
        codes={finalDrawCodes}
        isLoading={isLoading}
        hasError={!!hasError}
        onRetry={onRetry}
        emptyMessage={FINAL_DRAW_NO_CODES_MSG}
        mb="25px"
      />

      {!hasError && (
        <Link to={PATHS.MAPS}>
          <Button variant="primary" w="100%" py="13px" fontWeight="500">
            Заполнить карту
          </Button>
        </Link>
      )}
    </Box>
  </LayoutDesktop>
)
