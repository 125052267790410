import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userID: null,
  sessionID: null,
  closeUrl: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state.userID = payload.userID
      state.sessionID = payload.sessionID
      state.closeUrl = payload.closeUrl
    },
    clear: state => {
      state.userID = null
      state.sessionID = null
      state.closeUrl = null
    },
  },
})

export const { set: userInfoSet, unset: userInfoClear } = userSlice.actions

export const { reducer: userReducer } = userSlice

export default userSlice
