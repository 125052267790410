import { Button, Confetti, Text } from '../../ui/atoms'
import { PopupInfo } from '../../ui/molecules'

export const PopupFinalCode = ({ isOpened, onClose, onSubmitClick }) => (
  <PopupInfo
    isOpened={isOpened}
    onClose={onClose}
    hasLogo={false}
    hasCloseBtn={true}
  >
    <Confetti />

    <Text
      mb="32px"
      fontSize="19px"
      fontWeight="500"
      lineHeight="130%"
      letterSpacing="0.5px"
      textAlign="center"
      color="white"
    >
      Ты получил код для финального розыгрыша!
    </Text>

    <Button w="100%" onClick={onSubmitClick}>
      Ура!
    </Button>
  </PopupInfo>
)
