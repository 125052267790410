import React from 'react'
import styled from 'styled-components'

import { MAPS_MOBILE_BACKGROUND } from '../../constants/backgrounds'
import { TextTitle } from '../../ui/atoms'
import { ChipsInfo } from '../../ui/molecules'
import { LayoutMobile } from '../../ui/layout'
import { MapList } from '../../ui/organisms'

const TextTitleMaps = styled(TextTitle)`
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.textTertiaryColor}`};
`

export const MapsMobile = React.forwardRef(
  (
    {
      maps,
      isLoading,
      hasError,
      onRetry,
      isWideViewport,
      mapListTopOffset,
      mapListItemWidth,
    },
    ref
  ) => {
    return (
      <LayoutMobile ref={ref} {...MAPS_MOBILE_BACKGROUND}>
        <LayoutMobile.Header mb="24px">
          <TextTitleMaps
            fontSize="37px"
            fontWeight="500"
            lineHeight="130%"
            letterSpacing="0.5px"
            textAlign="center"
            mx="auto"
            mb="16px"
          >
            Мои карты
          </TextTitleMaps>

          <ChipsInfo
            isLoading={isLoading}
            hasError={hasError}
            onRetry={onRetry}
          />
        </LayoutMobile.Header>

        <LayoutMobile.Content>
          {!isLoading && !hasError && (
            <MapList
              maps={maps}
              isWideViewport={isWideViewport}
              topOffset={mapListTopOffset}
              listItemWidth={mapListItemWidth}
            />
          )}
        </LayoutMobile.Content>
      </LayoutMobile>
    )
  }
)
