import { useEffect, useRef, useState, useCallback } from 'react'
import useMatchMedia from 'react-use-match-media'

import { useMapsManager } from '../../hooks/useMapsManager'
import { MapsMobile } from './MapsMobile'
import { MapsDesktop } from './MapsDesktop'
import { GlobalStyle } from 'ui/settings/global'

const MAP_LIST_TOP_OFFSET_DELTA_MOBILE_PX = -10
const MAP_LIST_TOP_OFFSET_DELTA_DESKTOP_PX = 20

export const Maps = () => {
  const { maps, isLoading, error, fetchMaps } = useMapsManager()
  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const mapsRef = useRef(null)
  const [mapListTopOffset, setMapListTopOffset] = useState(0)
  const [mapListItemWidth, setMapListItemWidth] = useState(0)

  const scrollTop = () => {
    setTimeout(() => window.scrollTo(0, 0), 100)
    setTimeout(() => window.scrollTo(0, 0), 500)
  }

  const updateDimensions = () => {
    const { width: chipsInfoWrapperWidth, height: chipsInfoWrapperHeight } =
      mapsRef.current
        ?.querySelector('.chips-info-wrapper')
        ?.getBoundingClientRect() || {}

    const { height: layoutBodyHeight } =
      mapsRef.current
        ?.querySelector('.layout-mobile-body')
        ?.getBoundingClientRect() || {}

    setMapListItemWidth(chipsInfoWrapperWidth)
    setMapListTopOffset(layoutBodyHeight || chipsInfoWrapperHeight)
  }

  const updateDimensionsAndScrollTop = useCallback(() => {
    setTimeout(() => updateDimensions(), 0)
    scrollTop()
  }, [])

  useEffect(() => {
    updateDimensionsAndScrollTop()
  }, [isLoading, error, updateDimensionsAndScrollTop])

  useEffect(() => {
    window.addEventListener('resize', updateDimensionsAndScrollTop)
    return () =>
      window.removeEventListener('resize', updateDimensionsAndScrollTop)
  })

  return (
    <GlobalStyle>
      {isWideViewport ? (
        <MapsDesktop
          maps={maps}
          isLoading={isLoading}
          hasError={!!error}
          onRetry={fetchMaps}
          isWideViewport={isWideViewport}
          ref={mapsRef}
          mapListTopOffset={
            mapListTopOffset + MAP_LIST_TOP_OFFSET_DELTA_DESKTOP_PX
          }
          mapListItemWidth={mapListItemWidth}
        />
      ) : (
        <MapsMobile
          maps={maps}
          isLoading={isLoading}
          hasError={!!error}
          onRetry={fetchMaps}
          isWideViewport={isWideViewport}
          ref={mapsRef}
          mapListTopOffset={
            mapListTopOffset + MAP_LIST_TOP_OFFSET_DELTA_MOBILE_PX
          }
          mapListItemWidth={mapListItemWidth}
        />
      )}
    </GlobalStyle>
  )
}
