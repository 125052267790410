export const HOME_DESKTOP_BACKGROUND = {
  backgroundImage:
    'url(img/home_logo.png), url(img/clouds_bg.svg), url(img/rays_bg_desktop.svg)',
  backgroundPosition: {
    _: 'right 0 bottom 0, left 0 bottom 0, left -8% top 14%',
    xl: 'right 0 bottom 0, left 0 bottom 0, left -8% top 30%',
  },
  backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  backgroundSize: '55% auto, 100% auto, 138% auto',
}

export const HOME_MOBILE_BACKGROUND = {
  backgroundImage:
    'url(img/house_of_councils.png), url(img/clouds_bg.svg), url(img/rays_bg.svg)',
  backgroundPosition: '57% 100%, left 0 bottom 0, 50% 0%',
  backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  backgroundSize: {
    _: '60vw auto, 100% auto, 1658px 1658px',
    sm: '35% auto, 100% auto, 1658px 1658px',
  },
}

export const WEEKLY_DRAW_DESKTOP_BACKGROUND = {
  backgroundImage: 'url(img/other_pages_bg.png)',
  backgroundPosition: '0 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
}

export const WEEKLY_DRAW_MOBILE_BACKGROUND = {
  backgroundImage:
    'url(img/house_of_councils.png), url(img/clouds_bg.svg), url(img/rays_bg.svg)',
  backgroundPosition: '57% 100%, left 0 bottom 0, 50% 0%',
  backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  backgroundSize: {
    _: '60vw auto, 100% auto, 1658px 1658px',
    sm: '35% auto, 100% auto, 1658px 1658px',
  },
}

export const FINAL_DRAW_DESKTOP_BACKGROUND = {
  backgroundImage: 'url(img/other_pages_bg.png)',
  backgroundPosition: '0 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
}

export const FANAL_DRAW_MOBILE_BACKGROUND = {
  backgroundImage:
    'url(img/house_of_councils.png), url(img/clouds_bg.svg), url(img/rays_bg.svg)',
  backgroundPosition: '57% 100%, left 0 bottom 0, 50% 0%',
  backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  backgroundSize: {
    _: '60vw auto, 100% auto, 1658px 1658px',
    sm: '35% auto, 100% auto, 1658px 1658px',
  },
}

export const RULES_BACKGROUND = {
  backgroundImage: '',
  backgroundRepeat: '',
}

export const MAPS_MOBILE_BACKGROUND = {
  backgroundImage:
    'url(img/house_of_councils.png), url(img/clouds_bg.svg), url(img/rays_bg.svg)',
  backgroundPosition: '57% 100%, left 0 bottom 0, 50% 0%',
  backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  backgroundSize: {
    _: '60vw auto, 100% auto, 1658px 1658px',
    sm: '35% auto, 100% auto, 1658px 1658px',
  },
}

export const MAPS_DESKTOP_BACKGROUND = {
  backgroundImage: 'url(img/other_pages_bg.png)',
  backgroundPosition: '0 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
}

export const MAP_VIEW_MOBILE_BACKGROUND = {
  backgroundColor: '#52aa9a',
}

export const MAP_VIEW_DESKTOP_BACKGROUND = {
  backgroundColor: '#7abdb1',
}
