import styled from 'styled-components'
import { Link, useLocation, matchPath } from 'react-router-dom'

import { PATHS } from '../../routing/paths'
import { getNavLinkBasedOnLocation } from '../../utils/navigation'
import { Box, Button, Container, Flex } from '../../ui/atoms'
import { MainMenu } from '../../ui/organisms'

const NavbarDesktopContent = styled(Container)`
  display: flex;
  align-items: center;
`

const NavbarDesktopMenu = styled(MainMenu)`
  margin-left: auto;
`

const FamilyLogo = styled(Box)`
  position: relative;
  top: -4px;
`

const NavbarDesktopWrapper = styled(Box)`
  background-color: ${({ isMapView }) => {
    if (isMapView) {
      return '#669C26'
    } else {
      return 'rgba(69, 22, 216, 0.5)'
    }
  }};
`

export const NavbarDesktop = () => {
  const { pathname } = useLocation()
  const isHome = !!matchPath({ path: pathname, exact: true }, PATHS.HOME)
  const isMapView = !!matchPath({ path: pathname, exact: true }, PATHS.MAP_VIEW)

  return (
    <NavbarDesktopWrapper isMapView={isMapView} py="16px">
      <NavbarDesktopContent>
        {!isHome && (
          <Link to={getNavLinkBasedOnLocation(pathname)}>
            <Button
              as="div"
              variant={isMapView ? 'secondaryMapView' : 'secondaryAllOtherView'}
              mr="42px"
              px="16px"
              py="6px"
              borderRadius="12px"
            >
              <Box as="img" src="img/nav-left.svg" alt="" mr="12px" />
              Назад
            </Button>
          </Link>
        )}

        {!isMapView && (
          <Flex>
            <Box as="img" src="img/spar_logo.svg" alt="Spar" mr="37px" />
            <Box as="img" src="img/pobeda_logo.svg" alt="Победа" mr="37px" />
            <FamilyLogo as="img" src="img/semya_logo.svg" alt="Семья" />
          </Flex>
        )}

        <NavbarDesktopMenu isMapView={isMapView} />
      </NavbarDesktopContent>
    </NavbarDesktopWrapper>
  )
}
