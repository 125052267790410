export const BASE_ERROR_MESSAGE = 'Упс! Произошла ошибка. Попробуй позднее.'

export const WEEKLY_DRAW_REALIZE_FAIL_MESSAGE =
  'Упс! Произошла ошибка. Попробуй позднее.'

export const WEEKLY_DRAW_NO_CODES_MSG =
  'Регистрируй коды еженедельного розыгрыша и выигрывай призы!'

export const FINAL_DRAW_NO_CODES_MSG =
  'Регистрируй коды финального розыгрыша и выигрывай призы!'

export const CODES_LOADING_ERROR_MSG =
  'Упс! Произошла ошибка загрузки твоих кодов'

export const MAPS_LOADING_ERROR_MSG = 'Упс! Произошла ошибка загрузки карт'
