import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import couponCountSlice from './slices/couponCountSlice'
import mapsSlice from './slices/mapsSlice'
import userSlice from './slices/userSlice'
import finalDrawSlice from './slices/finalDrawSlice'
import weeklyDrawSlice from './slices/weeklyDrawSlice'

const rootReducer = combineReducers({
  user: userSlice.reducer,
  maps: mapsSlice.reducer,
  couponcount: couponCountSlice.reducer,
  weeklyDraw: weeklyDrawSlice.reducer,
  finalDraw: finalDrawSlice.reducer,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [userSlice.name],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)
