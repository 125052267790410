import styled from 'styled-components'

import { WEEKLY_DRAW_MOBILE_BACKGROUND } from '../../constants/backgrounds'
import { WEEKLY_DRAW_NO_CODES_MSG } from '../../constants/messages'
import { Box, TextTitle } from '../../ui/atoms'
import { Pager } from '../../ui/molecules'
import { LayoutMobile } from '../../ui/layout'
import { CodeList } from '../../ui/organisms'

const TextTitleWeeklyDrawMobile = styled(TextTitle)`
  text-shadow: ${({ theme }) => `3px 3px ${theme.colors.textTertiaryColor}`};
`

export const WeeklyDrawMobile = ({
  weeklyDrawCodes,
  isLoading,
  hasError,
  onRetry,
}) => (
  <LayoutMobile {...WEEKLY_DRAW_MOBILE_BACKGROUND}>
    <LayoutMobile.Header>
      <TextTitleWeeklyDrawMobile fontSize="37px" textAlign="center" mb="25px">
        Розыгрыши
      </TextTitleWeeklyDrawMobile>

      <Box mb="24px">
        <Pager />
      </Box>

      <TextTitle fontSize="16px" mb="22px" textAlign="center">
        Мои коды
      </TextTitle>
    </LayoutMobile.Header>

    <LayoutMobile.Content>
      <CodeList
        isWideScreen={false}
        codes={weeklyDrawCodes}
        isLoading={isLoading}
        hasError={!!hasError}
        onRetry={onRetry}
        isWeeklyDraw
        emptyMessage={WEEKLY_DRAW_NO_CODES_MSG}
        h="100%"
      />
    </LayoutMobile.Content>
  </LayoutMobile>
)
